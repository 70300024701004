import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

import Header from "../components/sub-components/Header";
import Footer from "../components/sub-components/Footer";

import "./sub-components/css/pricing.css";

import sk_file_to_download from "./media/sk-pricing.pdf";
import en_file_to_download from "./media/en-pricing.pdf";

// Reusable Standard Card Component
const PricingCardStandard = ({ header, price, from, vat, description }) => (
	<div className="col">
		<div className="card mb-4 rounded-3 shadow-sm">
			<div className="card-header py-3 text-white bg-secondary">
				<h4 className="my-0 fw-normal" dangerouslySetInnerHTML={{ __html: header }} />
			</div>
			<div className="card-body">
				<h1 className="card-title pricing-card-title">
					<small className="text-body-secondary fw-light">{from}</small> {price} <small className="text-body-secondary fw-light fs-6">{vat}</small> 
				</h1>
				<ul className="list-unstyled mt-3 mb-4 fs-5">
					{Array.isArray(description) ? (
						description.map((item, index) => (
							<li key={index}>
								<strong dangerouslySetInnerHTML={{ __html: item }} />
							</li>
						))
					) : (
							<li>
								<strong dangerouslySetInnerHTML={{ __html: description }} />
							</li>
					)}
				</ul>
			</div>
		</div>
	</div>
);

// Reusable Standard Plus Card Component
const PricingCardStandardPlus = ({ header, price, from, vat, description }) => (
	<div className="col">
		<div className="card mb-4 rounded-3 shadow-sm border-primary">
			<div className="card-header py-3 text-white bg-primary border-primary">
				<h4 className="my-0 fw-normal" dangerouslySetInnerHTML={{ __html: header }} />
			</div>
			<div className="card-body">
				<h1 className="card-title pricing-card-title">
					<small className="text-body-secondary fw-light">{from}</small> {price} <small className="text-body-secondary fw-light fs-6">{vat}</small> 
				</h1>
				<ul className="list-unstyled mt-3 mb-4 fs-5">
					{Array.isArray(description) ? (
						description.map((item, index) => (
							<li key={index}>
								<strong dangerouslySetInnerHTML={{ __html: item }} />
							</li>
						))
					) : (
						<li>
							<strong dangerouslySetInnerHTML={{ __html: description }} />
						</li>
					)}
				</ul>
			</div>
		</div>
	</div>
);

const Pricing = () => {
	const page_name = "Pricing";
	const { t, i18n } = useTranslation();

	const [fileToDownload, setFileToDownload] = useState(en_file_to_download);

	// Data for one-time cleaning
	const oneTimeCleaningStandardCards = [
		{
			header: t("one-time-cleaning-standard-card1-header", { ns: "pricing" }),
			price: t("one-time-cleaning-standard-card1-price", { ns: "pricing" }),
			from: t("card-title-from", { ns: "pricing" }),
			vat: t("card-vat-info", { ns: "pricing" }),
			description: t("one-time-cleaning-standard-card1-description", { ns: "pricing" }),
		},
		{
			header: t("one-time-cleaning-standard-card2-header", { ns: "pricing" }),
			price: t("one-time-cleaning-standard-card2-price", { ns: "pricing" }),
			from: t("card-title-from", { ns: "pricing" }),
			vat: t("card-vat-info", { ns: "pricing" }),
			description: t("one-time-cleaning-standard-card2-description", { ns: "pricing" }),
		},
		{
			header: t("one-time-cleaning-standard-card3-header", { ns: "pricing" }),
			price: t("one-time-cleaning-standard-card3-price", { ns: "pricing" }),
			from: t("card-title-from", { ns: "pricing" }),
			vat: t("card-vat-info", { ns: "pricing" }),
			description: t("one-time-cleaning-standard-card3-description", { ns: "pricing" }),
		},
		{
			header: t("one-time-cleaning-standard-card4-header", { ns: "pricing" }),
			price: t("one-time-cleaning-standard-card4-price", { ns: "pricing" }),
			from: t("card-title-from", { ns: "pricing" }),
			vat: t("card-vat-info", { ns: "pricing" }),
			description: t("one-time-cleaning-standard-card4-description", { ns: "pricing" }),
		},
		{
			header: t("one-time-cleaning-standard-card5-header", { ns: "pricing" }),
			price: t("one-time-cleaning-standard-card5-price", { ns: "pricing" }),
			from: t("card-title-from", { ns: "pricing" }),
			vat: t("card-vat-info", { ns: "pricing" }),
			description: t("one-time-cleaning-standard-card5-description", { ns: "pricing" }),
		},
		{
			header: t("one-time-cleaning-standard-card6-header", { ns: "pricing" }),
			price: t("one-time-cleaning-standard-card6-price", { ns: "pricing" }),
			description: t("one-time-cleaning-standard-card6-description", { ns: "pricing" }),
		},
		{
			header: t("one-time-cleaning-standard-card7-header", { ns: "pricing" }),
			price: t("one-time-cleaning-standard-card7-price", { ns: "pricing" }),
			description: t("one-time-cleaning-standard-card7-description", { ns: "pricing" }),
		},
	];

	const oneTimeCleaningStandardPlusCards = [
		{
			header: t("one-time-cleaning-standard-plus-card1-header", { ns: "pricing" }),
			price: t("one-time-cleaning-standard-plus-card1-price", { ns: "pricing" }),
			from: t("card-title-from", { ns: "pricing" }),
			vat: t("card-vat-info", { ns: "pricing" }),
			description: t("one-time-cleaning-standard-plus-card1-description", { ns: "pricing" }),
		},
		{
			header: t("one-time-cleaning-standard-plus-card2-header", { ns: "pricing" }),
			price: t("one-time-cleaning-standard-plus-card2-price", { ns: "pricing" }),
			from: t("card-title-from", { ns: "pricing" }),
			vat: t("card-vat-info", { ns: "pricing" }),
			description: t("one-time-cleaning-standard-plus-card2-description", { ns: "pricing" }),
		},
		{
			header: t("one-time-cleaning-standard-plus-card3-header", { ns: "pricing" }),
			price: t("one-time-cleaning-standard-plus-card3-price", { ns: "pricing" }),
			from: t("card-title-from", { ns: "pricing" }),
			vat: t("card-vat-info", { ns: "pricing" }),
			description: t("one-time-cleaning-standard-plus-card3-description", { ns: "pricing" }),
		},
		{
			header: t("one-time-cleaning-standard-plus-card4-header", { ns: "pricing" }),
			price: t("one-time-cleaning-standard-plus-card4-price", { ns: "pricing" }),
			from: t("card-title-from", { ns: "pricing" }),
			vat: t("card-vat-info", { ns: "pricing" }),
			description: t("one-time-cleaning-standard-plus-card4-description", { ns: "pricing" }),
		},
		{
			header: t("one-time-cleaning-standard-plus-card5-header", { ns: "pricing" }),
			price: t("one-time-cleaning-standard-plus-card5-price", { ns: "pricing" }),
			description: t("one-time-cleaning-standard-plus-card5-description", { ns: "pricing" }),
		},
		{
			header: t("one-time-cleaning-standard-plus-card6-header", { ns: "pricing" }),
			price: t("one-time-cleaning-standard-plus-card6-price", { ns: "pricing" }),
			description: t("one-time-cleaning-standard-plus-card6-description", { ns: "pricing" }),
		},
		{
			header: t("one-time-cleaning-standard-plus-card7-header", { ns: "pricing" }),
			price: t("one-time-cleaning-standard-plus-card7-price", { ns: "pricing" }),
			description: t("one-time-cleaning-standard-plus-card7-description", { ns: "pricing" }),
		},
	];

	// Data for regular cleaning
	const regularCleaningStandardCards = [
		{
			header: t("regular-cleaning-standard-card1-header", { ns: "pricing" }),
			from: t("card-title-from", { ns: "pricing" }),
			vat: t("card-vat-info", { ns: "pricing" }),
			price: t("regular-cleaning-standard-card1-price", { ns: "pricing" }),
			description: [],
		},
		{
			header: t("regular-cleaning-standard-card2-header", { ns: "pricing" }),
			from: t("card-title-from", { ns: "pricing" }),
			vat: t("card-vat-info", { ns: "pricing" }),
			price: t("regular-cleaning-standard-card2-price", { ns: "pricing" }),
			description: [],
		},
		{
			header: t("regular-cleaning-standard-card3-header", { ns: "pricing" }),
			from: t("card-title-from", { ns: "pricing" }),
			vat: t("card-vat-info", { ns: "pricing" }),
			price: t("regular-cleaning-standard-card3-price", { ns: "pricing" }),
			description: [],
		},
		{
			header: t("regular-cleaning-standard-card4-header", { ns: "pricing" }),
			from: t("card-title-from", { ns: "pricing" }),
			vat: t("card-vat-info", { ns: "pricing" }),
			price: t("regular-cleaning-standard-card4-price", { ns: "pricing" }),
			description: [],
		},
		{
			header: t("regular-cleaning-standard-card5-header", { ns: "pricing" }),
			price: t("regular-cleaning-standard-card5-price", { ns: "pricing" }),
			description: [],
		},
	];

	const regularCleaningStandardPlusCards = [
		{
			header: t("regular-cleaning-standard-plus-card1-header", { ns: "pricing" }),
			from: t("card-title-from", { ns: "pricing" }),
			vat: t("card-vat-info", { ns: "pricing" }),
			price: t("regular-cleaning-standard-plus-card1-price", { ns: "pricing" }),
			description: [],
		},
		{
			header: t("regular-cleaning-standard-plus-card2-header", { ns: "pricing" }),
			from: t("card-title-from", { ns: "pricing" }),
			vat: t("card-vat-info", { ns: "pricing" }),
			price: t("regular-cleaning-standard-plus-card2-price", { ns: "pricing" }),
			description: [],
		},
		{
			header: t("regular-cleaning-standard-plus-card3-header", { ns: "pricing" }),
			from: t("card-title-from", { ns: "pricing" }),
			vat: t("card-vat-info", { ns: "pricing" }),
			price: t("regular-cleaning-standard-plus-card3-price", { ns: "pricing" }),
			description: [],
		},
		{
			header: t("regular-cleaning-standard-plus-card4-header", { ns: "pricing" }),
			from: t("card-title-from", { ns: "pricing" }),
			vat: t("card-vat-info", { ns: "pricing" }),
			price: t("regular-cleaning-standard-plus-card4-price", { ns: "pricing" }),
			description: [],
		},
		{
			header: t("regular-cleaning-standard-plus-card5-header", { ns: "pricing" }),
			price: t("regular-cleaning-standard-plus-card5-price", { ns: "pricing" }),
			description: [],
		},
	];

	// Track language change and set the appropriate file
	useEffect(() => {
		const currentLanguage = i18n.language;
		if (currentLanguage === "sk") {
			setFileToDownload(sk_file_to_download);
		} else {
			setFileToDownload(en_file_to_download);
		}
	}, [i18n.language]);

	const handleDownload = () => {
		const link = document.createElement("a");
		const fileNameToAdd = t("file_name_of_file_to_download", { ns: "pricing" });

		// Set the href attribute to the selected file's URL
		link.href = fileToDownload;

		// Set the download attribute with a file name (optional)
		link.download = fileNameToAdd;

		// Append the link to the body
		document.body.appendChild(link);
		// Trigger a click event on the link
		link.click();
		// Remove the link from the document
		document.body.removeChild(link);
	};

	return (
		<>
			<Header page_name={page_name} />

			<main>
				{/* Introduction */}
				<div className="container">
					<div className="pricing-header p-3 pb-md-4 mx-auto my-5">
						<h1 className="display-1 fw-normal">{t("introduction-h1", { ns: "pricing" })}</h1>
						<p className="fs-5 text-muted">{t("introduction-p1", { ns: "pricing" })}</p>
						<p className="fs-5 text-muted">{t("introduction-p2", { ns: "pricing" })}</p>
						<p className="fs-5 text-muted">{t("pricing-validity", { ns: "pricing" })} 10.03.2025</p>
					</div>
				</div>

				{/* One-time cleaning pricing */}
				<div className="container">
					<div className="pricing-header pb-md-2 mx-auto">
						<h1 className="display-3 fw-normal">{t("one-time-cleaning-h1", { ns: "pricing" })}</h1>
						<p className="fs-5 text-muted">{t("one-time-cleaning-p", { ns: "pricing" })}</p>
					</div>
					<div className="card rounded-3 shadow-sm p-4 mb-5">
						<h1 className="display-5 fw-normal">{t("one-time-cleaning-standard-header", { ns: "pricing" })}</h1>
						<p className="fs-5 text-muted">{t("one-time-cleaning-standard-includes", { ns: "pricing" })}</p>

						<div className="row row-cols-1 row-cols-md-3 mb-3 text-center">
							{oneTimeCleaningStandardCards.map((card, index) => (
								<PricingCardStandard key={index} {...card} />
							))}
						</div>

						<h1 className="display-5 fw-normal mt-5 mb-3 ">{t("one-time-cleaning-standard-plus-header", { ns: "pricing" })}</h1>
						<p className="fs-5 mb-4 text-muted">{t("one-time-cleaning-standard-plus-includes", { ns: "pricing" })}</p>

						<div className="row row-cols-1 row-cols-md-3 mb-5 text-center">
							{oneTimeCleaningStandardPlusCards.map((card, index) => (
								<PricingCardStandardPlus key={index} {...card} />
							))}
						</div>
					</div>
				</div>

				{/* Regular cleaning pricing */}
				<div className="container">
					<div className="pricing-header pb-md-2 mx-auto mt-5">
						<h1 className="display-1 fw-normal">{t("regular-cleaning-h1", { ns: "pricing" })}</h1>
						<p className="fs-5 text-muted">{t("regular-cleaning-p", { ns: "pricing" })}</p>
					</div>
					<div className="card rounded-3 shadow-sm p-4 mb-5">
						<h1 className="display-5 fw-normal">{t("regular-cleaning-standard-header", { ns: "pricing" })}</h1>
						<p className="fs-5 text-muted">{t("regular-cleaning-standard-includes", { ns: "pricing" })}</p>

						<div className="row row-cols-1 row-cols-md-4 mb-3 text-center">
							{regularCleaningStandardCards.map((card, index) => (
								<PricingCardStandard key={index} {...card} />
							))}
						</div>

						<h1 className="display-5 fw-normal mt-5 mb-3 ">{t("regular-cleaning-standard-plus-header", { ns: "pricing" })}</h1>
						<p className="fs-5 mb-4 text-muted">{t("regular-cleaning-standard-plus-includes", { ns: "pricing" })}</p>

						<div className="row row-cols-1 row-cols-md-4 mb-3 text-center">
							{regularCleaningStandardPlusCards.map((card, index) => (
								<PricingCardStandardPlus key={index} {...card} />
							))}
						</div>
					</div>
				</div>

				{/* Clothing services */}
				<div className="container">
					<div className="pricing-header p-3 pb-md-4 mx-auto my-5">
						<h1 className="display-1 fw-normal">{t("clothing-ironing-h1", { ns: "pricing" })}</h1>
						<p className="fs-5 text-muted">{t("clothing-ironing-p", { ns: "pricing" })}</p>

						<button className="btn btn-primary" onClick={handleDownload}>
							{t("clothing-ironing-btn", { ns: "pricing" })}
						</button>
					</div>
				</div>
			</main>

			<Footer />
		</>
	);
};

export default Pricing;
