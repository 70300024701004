import React from "react";
import Header from "../components/sub-components/Header";
import Footer from "../components/sub-components/Footer";
import { useTranslation } from "react-i18next";
import { InstagramEmbed } from "react-social-media-embed";

const Contact = () => {
	const page_name = "Contact";
	const { t } = useTranslation();

	return (
		<>
			<Header page_name={page_name} />

			<main className="container">
				<h1 className="display-2 mt-4">{t("contact-h1", { ns: "contact" })}</h1>
				<p className="fs-5 text-muted mb-5">{t("contact-p", { ns: "contact" })}</p>

				{/* Information about company */}
				<div className="row my-5">
					<div className="col-md-6">
						<h2 className="fs-1">{t("contact-h2-email", { ns: "contact" })}</h2>
						<p>
							<a href="mailto:info@mp-uni.sk" className="fs-4 text-decoration-none">
								info@mp-uni.sk
							</a>
						</p>
						<h2 className="fs-1">{t("contact-h2-phone", { ns: "contact" })}</h2>
						<p>
							<a href={"tel:" + t("contact-a-phone-1", { ns: "contact" })} className="text-decoration-none fs-5">
								{t("contact-a-phone-1", { ns: "contact" })}{" "}
							</a>{" "}
							<br />
							<a href={"tel:" + t("contact-a-phone-2", { ns: "contact" })} className="text-decoration-none fs-5">
								{t("contact-a-phone-2", { ns: "contact" })}{" "}
							</a>
						</p>
					</div>
					<div className="col-md-6">
						<h2>{t("footer-info-h", { ns: "navigation" })}</h2>
						<ul className="list-unstyled">
							<li className="mb-2 text-body-secondary">MP-UNI, s.r.o.</li>
							<li className="mb-2 text-body-secondary">9. mája 745/16</li>
							<li className="mb-2 text-body-secondary">083 01 Sabinov</li>
							<li className="mb-2 text-body-secondary">{t("footer-company-ident", { ns: "navigation" })} 36818682</li>
							<li className="mb-2 text-body-secondary">{t("footer-tax-ident", { ns: "navigation" })} 2022424965</li>
							<li className="mb-2 text-body-secondary">{t("footer-vat-ident", { ns: "navigation" })} SK2022424965</li>
							<li className="mb-2 text-body-secondary">{t("footer-entry-number", { ns: "navigation" })}</li>
							<li className="mb-2 text-body-secondary">{t("footer-crdcp", { ns: "navigation" })}</li>
						</ul>
					</div>
				</div>

				{/* Instagram posts */}

				<div className="row my-5">
					<div className="col-12 col-md-4 mb-4">
						<div style={{ display: "flex", justifyContent: "center" }}>
							<InstagramEmbed url="https://www.instagram.com/p/DCzk79eOX8a/" width={328} />
						</div>
					</div>
					<div className="col-12 col-md-4 mb-4">
						<div style={{ display: "flex", justifyContent: "center" }}>
							<InstagramEmbed url="https://www.instagram.com/p/DB9WZhzOZsS/?img_index=1" width={328} />
						</div>
					</div>
					<div className="col-12 col-md-4 mb-4">
						<div style={{ display: "flex", justifyContent: "center" }}>
							<InstagramEmbed url="https://www.instagram.com/p/DAfvD_9OE0n/" width={328} />
						</div>
					</div>
				</div>
			</main>

			<Footer />
		</>
	);
};

export default Contact;
